















































































































































































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import RoleService, { LOCATION_PERMISSION_ID } from '@common-src/service/role-v2';
import { PermissionType, ResourceType } from '@common-src/model/enum';
import { PermissionEntityModel } from '@common-src/entity-model/permission-entity';
import { UserStoreModule } from '@common-src/store/modules/user';
import SpaceService from '@common-src/service/space-v2';
import { SpaceTreeEntityModel } from '@common-src/entity-model/space-entity';

@Component
export default class CorpDetailComponent extends DrawerComponent {
    permissionList: Array<PermissionEntityModel | SpaceTreeEntityModel> = null;
    modelId: string;
    permissionType: PermissionType = null;
    loading: boolean = true;
    notUser: boolean = true;
    ResourceType = ResourceType;
    entrance: string = undefined; // 入口 企业列表进来的权限 默认勾选选择页面的功能权限

    get IsPlatformPermission() {
        return this.permissionType === PermissionType.PLATFORM;
    }

    drawerRoleOpen(id: string, type: PermissionType, authority: string, entrance?:string) {
        this.entrance = entrance;
        this.modelId = id;
        this.permissionType = type;
        this.drawerVisible = true;
        this.notUser = authority !== 'USER';
        this.init();
    }

    init() {
        this.loading = true;
        RoleService.getPermissions(this.modelId, this.permissionType)
            .then(res => {
                this.permissionList = res;
                // if (!this.IsPlatformPermission) {
                //     this.initLocation();
                // }
                console.log('permissionList', this.permissionList);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    // initLocation() {
    //     SpaceService.getLocationTree().then(res => {
    //         const locationPermission: any = {
    //             id: LOCATION_PERMISSION_ID,
    //             name: '空间权限',
    //             children: res
    //         };
    //         this.permissionList.push(locationPermission);
    //     });
    // }

    treeItemOnClick(treeItem: {dataRef: SpaceTreeEntityModel}) {
        const checkValue = !treeItem.dataRef.check;
        this.checkTree(treeItem.dataRef, checkValue);
        if (checkValue) {
            this.checkParentTree(treeItem.dataRef.parent, true);
        }
    }

    private checkParentTree(treeParentItem, check: boolean) {
        if (!treeParentItem) {
            return;
        }
        treeParentItem.check = check;
        this.checkParentTree(treeParentItem.parent, check);
    }

    private checkTree(treeItem, check: boolean) {
        treeItem.check = check;
        _.forEach(treeItem.children, child => {
            this.checkTree(child, check);
        });
    }

    save() {
        const selectPermissionTree = _.map(_.filter(_.flatMapDepth(this.permissionList, 'children', 1), item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService(this.entrance));
        console.log('企业权限');
        console.log('selectPermissionTree', selectPermissionTree);
        const permissions = this.getSelectPermissions(selectPermissionTree);
        console.log('permissions', permissions);
        return RoleService.saveRolePrivs(this.modelId, permissions as any, this.permissionType).then(res => {
            if (!UserStoreModule.IsSuperAdmin) {
                this.$eventHub.$emit('MENU_RELOAD');
            }
            this.showMessageSuccess('保存权限成功');
        }).catch(err => {
            throw err;
        });
    }

    getSelectPermissions(treeData: Array<any>) {
        const permissions = [];
        _.forEach(treeData, item => {
            permissions.push(item.id);
            if (item.children && item.children.length > 0) {
                permissions.push(...this.getSelectPermissions(item.children));
            }
        });
        return permissions;
    }

    // 判断当前获取节点，是否为特殊对应的项目应用管理
    // appHandler(param: PermissionEntityModel) {
    //     if (param.name === '项目-应用') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}
